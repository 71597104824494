$(function () {
    resetComparisonMenusAndButtons();
});

function resetComparisonMenusAndButtons() {
    $('.button-sort').show();
    $('.button-sort-name').hide();
    
    $('.menuwrapper').hide();
    $('.wrapper-name').show();
}

function showMenu(comparisonsetId, menutype) {
    var menuFrame = $("#menus-" + comparisonsetId);

    $(menuFrame).find(':button').show();
    $(menuFrame).find('.button-sort-' + menutype).hide();

    $(menuFrame).find('.menuwrapper').hide();
    $(menuFrame).find('.wrapper-' + menutype).show();
}

originalOptions = {};

function filterRecommendationSelect(caller, recommendationCount, answerCount) {
    var target_selection_id = "evaluation_recommendations_attributes_" + recommendationCount + "_answer_recommendations_attributes_" + answerCount + "_answer_id";

    var filterText = $(caller).val();    

    filterMenuOptions(filterText, target_selection_id);
}

function filterComparisonsSelect(caller) {
    var targetSelectionId = 'compare';
    var filterText = $(caller).val();
    filterMenuOptions(filterText, targetSelectionId);
}

function filterMenuOptions(filterText, targetSelectionId) {
    var targetSelection = $('#' + targetSelectionId);
 
    if (!(originalOptions[targetSelectionId])) {
        originalOptions[targetSelectionId] = $(targetSelection).find('option');
    }

    var allOptions = originalOptions[targetSelectionId];
    var filteredOptions = [];
    
    for (var i=0; i<allOptions.length; i++) {
        var nextOption = allOptions[i];
        if (nextOption.label.toUpperCase().indexOf(filterText.toUpperCase()) != -1) {
            filteredOptions.push(nextOption);
        }
    }
    targetSelection.empty();
    for (var i=0; i<filteredOptions.length; i++) {
        var nextOption = filteredOptions[i];
        targetSelection.append(nextOption);
    }
}
